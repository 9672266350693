'use client';
import { Card, CardContent, Container, Typography } from '@mui/material';
import { useRouter } from 'next/navigation';

function Page() {
  const router = useRouter();
  const domainName = window.location.host;

  return (
    <Container maxWidth="sm">
      <Card variant="outlined" sx={{ padding: 2, marginTop: 4 }}>
        <CardContent>
          <Typography variant="h5" align="center" gutterBottom>
            Domain Name: {domainName}
          </Typography>
          <Typography variant="body1" align="center" gutterBottom>
            Please enter your company name correctly for example: {domainName}
            /[your company name]/login
          </Typography>
          <Typography variant="body1" align="center">
            {`If you don't have the domain name, please contact our tech support.`}
          </Typography>
        </CardContent>
      </Card>
    </Container>
  );
}

export default Page;
